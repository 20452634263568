import { PhoneNumberUtil } from 'google-libphonenumber';

import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  CountriesOptionsType,
  FieldControlType,
  FieldDefinition,
  FieldsType,
  fieldValidationService,
  LocalizableFieldType,
  SelectOptionsCountriesType,
  PhoneType,
  NO_SMS_VERIFICATION,
} from '../../../services';
import { BigIdMeTranslateType, LanguageType } from '../../../state/languageContainer';

export const mergeDefaultValues = ({
  fields,
  language,
  smsValidationOn,
  countriesOptions,
}: {
  fields: FieldDefinition[];
  language: LanguageType;
  smsValidationOn?: boolean;
  countriesOptions: CountriesOptionsType[];
}) =>
  fields.reduce((acc, { id, translations }) => {
    let userPhone: PhoneType | undefined = undefined;
    if (id === FieldsType.USER_PHONE) {
      const phoneService = PhoneNumberUtil.getInstance();
      try {
        const phone = phoneService.parse(translations[language]?.defaultValue);
        userPhone = {
          phone: '+' + phone.getCountryCode() + phone.getNationalNumber(),
          countryCode: phoneService.getRegionCodeForCountryCode(phone.getCountryCode() || 0),
          validationId: smsValidationOn ? undefined : NO_SMS_VERIFICATION,
        };
      } catch {
        const isCountyCode = translations[language]?.defaultValue?.length === 2;
        userPhone = {
          phone: isCountyCode ? '' : translations[language]?.defaultValue || '',
          countryCode: isCountyCode ? translations[language]?.defaultValue : '',
          validationId: smsValidationOn ? undefined : NO_SMS_VERIFICATION,
        };
      }
    }

    let countryDivision: string | undefined = undefined;
    if (id === FieldsType.USER_COUNTRY_DIVISION) {
      const defaultCountry = fields.find(item => item.id === FieldsType.USER_COUNTRY)?.translations[language]
        ?.defaultValue;
      const defaultCountryDivision = fields.find(item => item.id === FieldsType.USER_COUNTRY_DIVISION)?.translations[
        language
      ]?.defaultValue;

      const countryDivisions = countriesOptions.find(
        ({ value }: SelectOptionsCountriesType) => defaultCountry === value,
      );

      countryDivision = countryDivisions?.divisions.find(
        item => item.value === defaultCountryDivision || item.label === defaultCountryDivision,
      )?.value;
    }

    return translations[language]?.defaultValue
      ? {
          ...acc,
          // default value for phone is county code and here must be phone number
          [id]: userPhone || countryDivision || translations[language]?.defaultValue,
        }
      : acc;
  }, {});

export const normalizeDefaultFields = ({
  fields,
  language,
  smsValidationOn = false,
  translate,
  countriesOptions,
}: {
  fields: FieldDefinition[];
  language: LanguageType;
  smsValidationOn?: boolean;
  translate: BigIdMeTranslateType;
  countriesOptions: CountriesOptionsType[];
}) => {
  let fieldValuesWithDefaults = mergeDefaultValues({ fields, language, smsValidationOn, countriesOptions });
  let fieldValidation = fieldValidationService.validate(fields, fieldValuesWithDefaults, translate, language);

  let globalIsForceEditable = false;
  const defaultFieldNormalized = fields.map(field => {
    const isForceEditable = field.fieldControl === FieldControlType.READONLY && !!fieldValidation.messages[field.id];
    const normalizeTranslation = Object.keys(field.translations).reduce<Record<string, LocalizableFieldType>>(
      (acc, lang) => {
        acc[lang] = {
          ...field.translations[lang],
          defaultValue: isForceEditable ? '' : field.translations[language]?.defaultValue,
        };
        return acc;
      },
      {},
    );
    globalIsForceEditable = globalIsForceEditable ? globalIsForceEditable : isForceEditable;
    const newFieldControl = isForceEditable ? FieldControlType.EDITABLE : field.fieldControl;
    return {
      ...field,
      defaultValue: isForceEditable ? '' : field.translations[language]?.defaultValue,
      fieldControl: newFieldControl,
      translations: normalizeTranslation,
    };
  });
  if (globalIsForceEditable) {
    fieldValuesWithDefaults = mergeDefaultValues({
      fields: defaultFieldNormalized,
      language,
      smsValidationOn,
      countriesOptions,
    });
    fieldValidation = fieldValidationService.validate(
      defaultFieldNormalized,
      fieldValuesWithDefaults,
      translate,
      language,
    );
  }

  return { defaultFieldNormalized, fieldValuesWithDefaults, fieldValidation };
};

export const mergeAgentDefaultValues = ({
  fields,
  language,
  smsValidationOn,
  countriesOptions,
}: {
  fields: AuthorizedAgentFieldType[];
  language: LanguageType;
  smsValidationOn?: boolean;
  countriesOptions: CountriesOptionsType[];
}) =>
  fields.reduce((acc, { translations, name }) => {
    let userPhone: PhoneType | undefined = undefined;
    if (
      name === AuthorizedAgentFieldNameType.PHONE_NUMBER ||
      name === AuthorizedAgentFieldNameType.MOBILE_PHONE_NUMBER
    ) {
      const phoneService = PhoneNumberUtil.getInstance();
      try {
        const phone = phoneService.parse(translations[language]?.defaultValue);
        userPhone = {
          phone: '+' + phone.getCountryCode() + phone.getNationalNumber(),
          countryCode: phoneService.getRegionCodeForCountryCode(phone.getCountryCode() || 0),
          validationId: smsValidationOn ? undefined : NO_SMS_VERIFICATION,
        };
      } catch {
        const isCountyCode = translations[language]?.defaultValue?.length === 2;
        userPhone = {
          phone: isCountyCode ? '' : translations[language]?.defaultValue || '',
          countryCode: isCountyCode ? translations[language]?.defaultValue : '',
          validationId: smsValidationOn ? undefined : NO_SMS_VERIFICATION,
        };
      }
    }
    let state: string | undefined = undefined;
    if (name === AuthorizedAgentFieldNameType.STATE) {
      const defaultCountry = fields.find(item => item.name === AuthorizedAgentFieldNameType.COUNTRY)?.translations[
        language
      ]?.defaultValue;
      const defaultCountryDivision = fields.find(item => item.name === AuthorizedAgentFieldNameType.STATE)
        ?.translations[language]?.defaultValue;

      const countryDivisions = countriesOptions.find(
        ({ value }: SelectOptionsCountriesType) => defaultCountry === value,
      );

      state = countryDivisions?.divisions.find(
        item => item.value === defaultCountryDivision || item.label === defaultCountryDivision,
      )?.value;
    }

    return translations[language]?.defaultValue
      ? {
          ...acc,
          // default value for phone is county code and here must be phone number
          [name]: userPhone || state || translations[language]?.defaultValue,
        }
      : acc;
  }, {});

export const normalizeAgentDefaultFields = ({
  fields,
  language,
  smsValidationOn = false,
  translate,
  countriesOptions,
}: {
  fields: AuthorizedAgentFieldType[];
  language: LanguageType;
  smsValidationOn?: boolean;
  translate: BigIdMeTranslateType;
  countriesOptions: CountriesOptionsType[];
}) => {
  const fieldValuesWithDefaults = mergeAgentDefaultValues({ fields, language, smsValidationOn, countriesOptions });
  const fieldValidation = fieldValidationService.validateAuthorizedAgentFields(
    fields,
    fieldValuesWithDefaults,
    translate,
    language,
  );

  const defaultFieldNormalized = fields.map(field => {
    const normalizeTranslation = Object.keys(field.translations).reduce<Record<string, LocalizableFieldType>>(
      (acc, lang) => {
        acc[lang] = {
          ...field.translations[lang],
          defaultValue: field.translations[language]?.defaultValue,
        };
        return acc;
      },
      {},
    );
    return {
      ...field,
      defaultValue: field.translations[language]?.defaultValue,
      translations: normalizeTranslation,
    };
  });

  return { defaultFieldNormalized, fieldValuesWithDefaults, fieldValidation };
};
