import axios, { AxiosRequestConfig } from 'axios';
import { appFwService } from './appFwService';
import { getTenantFromLocation } from '../utils/commonUtils';
import { datadogRum } from '@datadog/browser-rum';
import { isBigIdCoreAppWithProxy } from '../utils/appUtils';

const isDatadogRumAvailable = datadogRum && typeof datadogRum.addError === 'function';

// ignore these codes in DD
const WARN_ERROR_CODES = [11401, 11430, 11442, 12421];

export const api = axios.create({
  baseURL: process.env.REACT_APP_WORKFLOW_API_BASE_URL,
  responseType: 'json',
});

api.interceptors.request.use(async config => {
  if (isBigIdCoreAppWithProxy()) {
    await setBigIDCoreAppWithProxyRequestOptions(config);
  } else {
    setStandaloneAppRequestOptions(config);
  }
  return config;
});

const setBigIDCoreAppWithProxyRequestOptions = async (config: AxiosRequestConfig) => {
  if (isBigIdCoreAppWithProxy()) {
    const token = await appFwService.getToken();
    const bffUrl = await appFwService.getBffUrl();
    config.baseURL = bffUrl + config.baseURL;
    if (!config.headers) {
      config.headers = {};
    }
    (config.headers as Record<string, string>).Authorization = token;
  }
};

const setStandaloneAppRequestOptions = (config: AxiosRequestConfig) => {
  config.url = config.baseURL ? getTenantFromLocation() + config.url : config.url;
};

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.data?.code && !WARN_ERROR_CODES.includes(error.response.data.code) && isDatadogRumAvailable) {
      // Log error to Datadog
      datadogRum.addError({
        ...error.response?.data,
        url: error.response?.config?.url,
      });
    }
    return Promise.reject(error);
  },
);

export default api;
