import makeStyles from '@mui/styles/makeStyles';
import { errorColor } from '../../assets/theme/BigidMeColors';

export const useStyles = makeStyles(theme => ({
  root: {},
  // Hidding flags icons in input and in a dropdown
  '@global': {
    '.react-tel-input': {
      width: 'auto',
    },
    '[dir=rtl] .react-tel-input': {
      '& .selected-flag': {
        padding: '0 0 0 8px',
      },
      '& .arrow': {
        left: '20px',
      },
      '& .country-list .flag': {
        marginRight: '7px',
        marginLeft: 0,
      },
      '& .country-list .country-name': {
        display: 'inline-block',
        marginRight: '6px',
        marginLeft: 0,
      },
      '& .form-control': {
        direction: 'ltr!important',
      },
    },
    '.react-tel-input .flag-dropdown': {
      borderRadius: '18px 0 0 18px !important',
      border: '1px solid #888888 !important',
      zIndex: 1,
    },
    '.react-tel-input .selected-flag': {
      backgroundColor: 'transparent !important',

      '&:focus': {
        outline: 'auto',
      },
    },
    '.react-tel-input .form-control': {
      paddingLeft: '43px !important',
      border: '1px solid #888888 !important',
      borderRadius: '18px !important',
    },
    '.react-tel-input .flag-dropdown.open': {
      borderRadius: '15px 0 0 0 !important',
    },
  },
  container: {
    display: 'flex',
    marginTop: 25,
    alignItems: 'start',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& .react-tel-input .form-control': {
      width: 240,
      height: 37,

      [theme.breakpoints.down('sm')]: {
        width: 288,
      },
    },

    '& ul': {
      zIndex: 2,
    },
  },
  inputContainer: {
    flexDirection: 'column',
  },
  verifyButton: {
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0',
    },
  },
  labelRoot: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.caption.fontSize,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: '0.875rem',
      transform: 'none',
    },
  },
  labelFocused: {
    color: `${theme.palette.text.secondary} !important`,
  },
  inputRoot: {
    fontSize: theme.typography.h6.fontSize,
    color: theme.palette.text.primary,
    '&::placeholder': {
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.text.primary,
    },
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
    '&:focus-within': {
      outline: 'auto',
      outlineColor: '#888',
    },
  },
  inputError: {
    border: `1px solid ${errorColor} !important`,
    boxShadow: '0 0 8px 0 rgba(252,92,66,0.5)',
  },
  inputDisabled: {
    backgroundColor: '#F8F8F8',
  },
  helperTextRoot: {
    // to mach with input width, with margin it will be 240
    width: 212,
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    color: `${errorColor} !important`,
  },
  italic: {
    // needed for rtl
    display: 'inline-block',
    fontStyle: 'italic',
    fontWeight: 300,
  },
  statusLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    width: 'fit-content',
    border: 'none',
    borderRadius: 8,
    backgroundColor: '#f4fcf9',
    margin: '0 10px',
    fontSize: '0.8125rem',
    color: '#292929',
    padding: '7px 14px 7px 8px',
    fontWeight: 800,
    lineHeight: '1rem',
    '& span': {
      paddingLeft: 10,
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      margin: '15px 0',
      border: '1px solid #4dd2a4',
    },
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 8,
    marginTop: 21,

    '& > svg': {
      margin: '0 15px 0 0',
      minWidth: 20,
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 0,
      marginLeft: 0,
      alignItems: 'center',

      '& > svg': {
        marginRight: 13,
        minWidth: 20,
      },
    },
  },
  helperTextWithError: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 4,
    },
  },
  visualHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: '1px',
  },
}));
