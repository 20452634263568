import { PhoneNumberUtil } from 'google-libphonenumber';
import {
  AuthorizedAgentFieldNameType,
  AuthorizedAgentFieldType,
  AuthorizedAgentFormFieldType,
  DeliveryMethodTypes,
  FieldControlType,
  FieldDefinition,
  FieldValue,
  FormFieldType,
  PhoneType,
} from '../request/types';

import { BigIdMeTranslateType, LanguageType } from '../../state/languageContainer';
const MAX_EMAIL_SIZE = 254;
export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const cpfRegEx =
  // eslint-disable-next-line es/no-regexp-lookbehind-assertions
  /(?<!\-|\.)\b\d{3}(?:(?:\.\d{3}){2}\-|\d{6}[ \t\-]?)\d{2}\b(?<!000\.?000\.?000[ \t\-]?00|111\.?111\.?111[ \t\-]?11|222\.?222\.?222[ \t\-]?22|333\.?333\.?333[ \t\-]?33|444\.?444\.?444[ \t\-]?44|555\.?555\.?555[ \t\-]?55|666\.?666\.?666[ \t\-]?66|777\.?777\.?777[ \t\-]?77|888\.?888\.?888[ \t\-]?88|999\.?999\.?999[ \t\-]?99)(?!\-|\.\d)/;

export const ssnRegEx =
  // eslint-disable-next-line es/no-regexp-lookbehind-assertions
  /(?<![\-\$\.@])(?!(\d)\1{2}[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]\1{2}[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]\1{4})\b(?:0(?:0[1-9]|[1-9]\d)|[1-578]\d{2}|6(?:[0-57-9]\d|6[0-57-9]))([ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0])(?:0[1-9]|[1-9]\d)\2(?:0(?:0(?:0[1-9]|[1-9]\d)|[1-9]\d{2})|[1-9]\d{3})\b(?![\-\u2010\u2011\u2012\u2013\u2014\u2015@$]|\.\d)(?<!(?:123[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]45[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]6789)|987[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]65[ \t\-\u2010\u2011\u2012\u2013\u2014\u2015\uff0d\u00A0]4321)/;

const makeErrorMessageFactory =
  (translate: BigIdMeTranslateType, language: LanguageType) => (field: FieldDefinition, translationKey: string) =>
    `${field.translations[language]?.label} ${translate(translationKey)}`;

const makeAuthorizedAgentErrorMessageFactory =
  (translate: BigIdMeTranslateType, language: LanguageType) =>
  (field: AuthorizedAgentFieldType, translationKey: string) =>
    `${field.translations[language]?.title} ${translate(translationKey)}`;

const validate = (
  fields: FieldDefinition[],
  values: { [id: string]: FieldValue },
  translate: BigIdMeTranslateType,
  language: LanguageType,
): FieldValidation => {
  const makeErrorMessage = makeErrorMessageFactory(translate, language);

  const messages: Record<string, string> = {};
  let valid = true;

  fields.forEach(f => {
    const isEnabledAndRequiredFieldOnMailDM =
      values.deliveryMethod === DeliveryMethodTypes.email && f.enabled && f.type.constraints?.required;

    const isEnabledAndRequiredFieldOnPhysicalMailDM =
      values.deliveryMethod === DeliveryMethodTypes.physicalMail &&
      f.enabledForPhysicalMail &&
      f.type.constraints?.physicalRequired;

    const isEnabledAndRequiredField = isEnabledAndRequiredFieldOnMailDM || isEnabledAndRequiredFieldOnPhysicalMailDM;

    const isEnabledOnPhysicalMailDeliveryMethod =
      values.deliveryMethod === DeliveryMethodTypes.physicalMail && f.enabledForPhysicalMail;

    const isEnabledOnMailDeliveryMethod = values.deliveryMethod === DeliveryMethodTypes.email && f.enabled;

    const isEnabledField = isEnabledOnMailDeliveryMethod || isEnabledOnPhysicalMailDeliveryMethod;

    if (!!isEnabledAndRequiredField && !values[f.id]) {
      messages[f.id] = makeErrorMessage(f, 'consumer_web_validation_empty');
      valid = false;
    } else if (
      f.type.regExpType === 'email' &&
      (isEnabledAndRequiredField || isEnabledField) &&
      values[f.id] &&
      (!emailRegEx.test((values[f.id] as string) || '') || values[f.id]!.toString().length > MAX_EMAIL_SIZE)
    ) {
      messages[f.id] = makeErrorMessage(f, 'consumer_web_validation_incorrect-email');
      valid = false;
    } else if (f.type.regExpType === 'phone') {
      if (isEnabledAndRequiredField && !(values[f.id] && (values[f.id] as PhoneType).phone)) {
        messages[f.id] = makeErrorMessage(f, 'consumer_web_validation_empty');
        valid = false;
      } else {
        const phoneValue = values[f.id] as PhoneType | undefined;
        const phoneUtil = PhoneNumberUtil.getInstance();
        if (phoneValue?.phone) {
          let isPhoneValid = false;
          try {
            isPhoneValid = phoneUtil.isValidNumberForRegion(
              phoneUtil.parse(phoneValue.phone, phoneValue.countryCode),
              phoneValue.countryCode,
            );
          } catch {
            isPhoneValid = false;
          }
          if (!isPhoneValid && isEnabledField) {
            messages[f.id] = makeErrorMessage(f, 'consumer_web_validation_incorrect_phone');
            valid = false;
          }
        }
      }
    } else if (f.fieldType === FormFieldType.DATE) {
      const minDate = f.type.constraints['minDate']?.value;
      const maxDate = f.type.constraints['maxDate']?.value;
      const dateValue = values[f.id] as string;
      if (
        dateValue &&
        (Number.isNaN(new Date(dateValue).getTime()) ||
          (minDate ? new Date(dateValue) < new Date(minDate as string) : false) ||
          (maxDate ? new Date(dateValue) > new Date(maxDate as string) : false))
      ) {
        messages[f.id] = makeErrorMessage(f, 'consumer_web_validation_incorrect_date');
        valid = false;
      }
    } else if (
      (isEnabledAndRequiredField || values[f.id]) &&
      ((f.type.regExpType === 'cpf' && !cpfRegEx.test((values[f.id] as string) || '')) ||
        (f.type.regExpType === 'ssn' && !ssnRegEx.test((values[f.id] as string) || '')))
    ) {
      messages[f.id] = makeErrorMessage(f, 'consumer_web_validation_incorrect-national-id');
      valid = false;
    }
    if (f.fieldControl === FieldControlType.HIDDEN || f.fieldControl === FieldControlType.READONLY) {
      valid = true;
      messages[f.id] = '';
    }
  });

  return { valid, messages };
};

const validateAuthorizedAgentFields = (
  fields: AuthorizedAgentFieldType[],
  values: { [id: string]: FieldValue },
  translate: BigIdMeTranslateType,
  language: LanguageType,
): FieldValidation => {
  const makeErrorMessage = makeAuthorizedAgentErrorMessageFactory(translate, language);

  const messages: Record<string, string> = {};
  let valid = true;

  fields.forEach(f => {
    const required = f.required && f.enabled;

    if (!!required && !values[f.name]) {
      messages[f.name] = makeErrorMessage(f, 'consumer_web_validation_empty');
      valid = false;
    } else if (
      f.name === AuthorizedAgentFieldNameType.EMAIL &&
      (required || values[f.name]) &&
      (!emailRegEx.test((values[f.name] as string) || '') || values[f.name]!.toString().length > MAX_EMAIL_SIZE)
    ) {
      messages[f.name] = makeErrorMessage(f, 'consumer_web_validation_incorrect-email');
      valid = false;
    } else if (f.type === AuthorizedAgentFormFieldType.PHONE) {
      if (required && (!values[f.name] || !(values[f.name] as PhoneType).phone)) {
        messages[f.name] = makeErrorMessage(f, 'consumer_web_validation_empty');
        valid = false;
      } else {
        const phoneValue = values[f.name] as PhoneType | undefined;
        const phoneUtil = PhoneNumberUtil.getInstance();
        if (phoneValue?.phone) {
          let isPhoneValid = false;
          try {
            isPhoneValid = phoneUtil.isValidNumberForRegion(
              phoneUtil.parse(phoneValue.phone, phoneValue.countryCode),
              phoneValue.countryCode,
            );
          } catch {
            isPhoneValid = false;
          }
          if (!isPhoneValid) {
            messages[f.name] = makeErrorMessage(f, 'consumer_web_validation_incorrect_phone');
            valid = false;
          }
        }
      }
    } else if (
      (required || values[f.name]) &&
      ((f.valueRegexType === 'cpf' && !cpfRegEx.test((values[f.name] as string) || '')) ||
        (f.valueRegexType === 'ssn' && !ssnRegEx.test((values[f.name] as string) || '')))
    ) {
      messages[f.name] = makeErrorMessage(f, 'consumer_web_validation_incorrect-national-id');
      valid = false;
    }
  });

  return { valid, messages };
};

export const fieldValidationService = {
  validate,
  validateAuthorizedAgentFields,
};

export interface FieldValidation {
  valid: boolean;
  messages: Record<string, string>;
}
