import { changeUrlProtocolFromHttpToWs, isUrlAbsolute, makeUrlRelative } from './urlUtils';

export const getBigIDCoreAppWithProxyBaseUrl = () => new URL(document.querySelector('body')?.baseURI || '');

export const getBigIDCoreAppWithProxyWsBaseUrl = () => {
  const url = getBigIDCoreAppWithProxyBaseUrl();
  changeUrlProtocolFromHttpToWs(url);
  url.pathname = url.pathname.replace('/ui', '/ws');
  return url;
};

export const getOriginalAppUiUrl = () =>
  (document.querySelector("meta[name='original_app_ui_url']") as HTMLMetaElement)?.content;

export const resolveUrlForBigIDCoreAppWithProxy = (url?: string) => {
  if (!url || isUrlExternal(url)) return url;
  return makeUrlRelativeToApp(url);
};

const isUrlExternal = (url: string) => isUrlAbsolute(url) && !url.includes(getOriginalAppUiUrl());

const makeUrlRelativeToApp = (url: string) => makeUrlRelative(url, getOriginalAppUiUrl());
