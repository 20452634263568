import React, { FC, useEffect, ReactNode } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { BigidMeSelect } from '../BigidMeSelect';
import { ReactComponent as BigidMeIconLanguage } from '../../assets/icons/Language.svg';
import { LanguageContainer, LanguageType } from '../../state/languageContainer';
import { e2e, TranslationsType } from '../../utils';
import { BigidMergerLogoPrivacy } from '@bigid-ui/icons';
import { ReactComponent as TermsIcon } from '../../assets/icons/mobile_terms.svg';
import { ReactComponent as ContactIcon } from '../../assets/icons/mobile_contact.svg';
import { ReactComponent as PrivacyIcon } from '../../assets/icons/mobile_privacy.svg';
import { MeBurgerMenu } from '../MeBurgerMenu';
import { useResponsive } from '../../hooks/useResponsive';

import { useStyles } from './BigidMeWireframeStyles';
import { isValidURL } from '../../utils/commonUtils';
import { parseHTML } from '../../utils';
import { setPageTitle } from '../../utils/helpers';
import { BigidMeImage } from '../BigidMeImage';
import { resolveUrlForApp } from '../../utils/appUtils';

export const CONSUMER_APP_CONTENT_ID = 'consumer-app-content';
export const DEFAULT_LOGO_SIZE = 40;

export interface BigidMeWireframePropsType {
  tenantName: string;
  logoUrl: string;
  logoWidth: string | number;
  logoHeight: string | number;
  supportLink: string;
  termsConditionsLink?: string;
  privacyPolicyLink?: string;
  hideLanguageControls?: boolean;
  whitelabelBigidmeIcon?: boolean;
  headerTitle?: string;
  isPreviewPP?: boolean;
  pageTitle?: string;
  copyrightEnabled?: boolean;
  translations: TranslationsType;
  children?: ReactNode;
}

export const BigidMeWireframe: FC<BigidMeWireframePropsType> = ({
  tenantName,
  logoUrl,
  logoWidth,
  logoHeight,
  supportLink,
  termsConditionsLink,
  privacyPolicyLink,
  whitelabelBigidmeIcon,
  hideLanguageControls,
  isPreviewPP,
  copyrightEnabled,
  children,
  pageTitle,
  translations,
}) => {
  const isMobile = useResponsive();
  const classes = useStyles({
    logoWidth: logoWidth === 'auto' ? (!logoUrl && isPreviewPP ? 0 : DEFAULT_LOGO_SIZE) : +logoWidth,
    logoHeight: logoHeight === 'auto' ? (!logoUrl && isPreviewPP ? 0 : DEFAULT_LOGO_SIZE) : +logoHeight,
    logoUrl: resolveUrlForApp(logoUrl) || logoUrl,
  });
  const { language, setLanguage, availableLanguages, dictionaryLoading, BigIdMeTranslate } =
    LanguageContainer.useContainer();

  // set page title
  useEffect(() => {
    if (pageTitle) {
      setPageTitle(pageTitle);
    }
  }, [pageTitle]);

  const handleLangChange = (value: string) => {
    document.documentElement.setAttribute('lang', value);
    localStorage.setItem('lang', value);
    setLanguage(value as LanguageType);
  };

  const tenantCaption = translations[language]?.headerTitle;
  const tenantCopyright =
    (isPreviewPP && copyrightEnabled && translations[language]?.copyright) ||
    (!isPreviewPP && translations[language]?.copyright);

  const bottomLinks = [
    {
      title: translations[language]?.supportTitle || '',
      url: supportLink,
      icon: <TermsIcon />,
      testId: 'supportLink',
    },
    {
      title: translations[language]?.privacyPolicyTitle || '',
      url: privacyPolicyLink,
      icon: <PrivacyIcon />,
      testId: 'privacyPolicyLink',
    },
    {
      title: translations[language]?.termsConditionsTitle || '',
      url: termsConditionsLink,
      icon: <ContactIcon />,
      testId: 'termsConditionsLink',
    },
  ];

  return (
    <div className={classes.root}>
      <header className={classes.topBar}>
        {isMobile && (
          <MeBurgerMenu
            hideLanguageControls={hideLanguageControls}
            bottomLinks={bottomLinks}
            onLanguageChange={handleLangChange}
          />
        )}
        {!!logoUrl && (
          <div
            className={classes.logoContainer}
            data-aid="consumer_logo_container"
            // for cypress tests
            data-width={logoWidth}
            // for cypress tests
            data-height={logoHeight}
          >
            {/* for cypress tests */}
            <BigidMeImage
              src={logoUrl}
              className={classes.hiddenLogo}
              {...e2e('consumer_logo')}
              data-aid="consumer_logo"
              alt={`${tenantName} logo`}
            />
          </div>
        )}
        <Typography variant="h1" color="textPrimary" className={classes.caption} noWrap>
          {tenantCaption}
        </Typography>
        <div className={classes.controls}>
          {!isMobile && !hideLanguageControls && availableLanguages && availableLanguages!.length > 1 && (
            <div className={classes.langSelectorContainer}>
              <div className={classes.langIconWrapper}>
                <BigidMeIconLanguage title="Language icon" />
              </div>
              <BigidMeSelect
                value={availableLanguages?.filter(({ value }) => value === language)}
                onChange={handleLangChange}
                options={availableLanguages}
                className={classes.langSelector}
                id="select-language"
                label="Select country"
                hiddenLabel
                lang
                {...e2e(`select_language`)}
              />
            </div>
          )}
        </div>
      </header>
      <div className={classes.container} id={CONSUMER_APP_CONTENT_ID}>
        {dictionaryLoading ? <CircularProgress className={classes.loader} /> : children}
      </div>

      <footer className={classes.bottomBar}>
        <div className={classes.bottomBarItems}>
          {bottomLinks.map(
            ({ title, url, testId }) =>
              !!url &&
              isValidURL(url) && (
                <Typography variant="body1" color="textPrimary" key={title} className={classes.link}>
                  <a
                    target="_blank"
                    {...e2e(testId)}
                    rel="noopener noreferrer"
                    href={url}
                    color="inherit"
                    data-aid={testId}
                  >
                    {title}
                  </a>
                </Typography>
              ),
          )}
        </div>
        {tenantCopyright && (
          <div className={classes.bottomBarItems}>
            <Typography variant="body1" color="textPrimary" data-aid="consumer_copyright_field">
              {parseHTML(tenantCopyright)}
            </Typography>
          </div>
        )}
        {whitelabelBigidmeIcon === false && (
          <div className={classes.bottomBarItems}>
            {BigIdMeTranslate('powered_by_bigid_me') || 'Powered by'}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bigid.me"
              className={classes.link}
              color="inherit"
            >
              <BigidMergerLogoPrivacy />
            </a>
          </div>
        )}
      </footer>
    </div>
  );
};
