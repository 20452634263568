export const changeUrlProtocolFromHttpToWs = (url: URL) => {
  if (url.protocol === 'https:') {
    url.protocol = 'wss:';
  } else {
    url.protocol = 'ws:';
  }
};

export const isUrlAbsolute = (url: string) => {
  return url.startsWith('http://') || url.startsWith('https://');
};

export const makeUrlRelative = (url: string, base?: string) => {
  const urlWithoutBase = removeBaseFromUrl(url, base);
  return removeStartingSlashFromUrl(urlWithoutBase);
};

const removeBaseFromUrl = (url: string, base?: string) => {
  if (base) {
    return url.replace(base, '');
  }
  return url;
};

const removeStartingSlashFromUrl = (url: string) => {
  if (url.startsWith('/')) {
    return url.substring(1);
  }
  return url;
};
