import React, { FC, useEffect } from 'react';
import { Divider, IconButton, Stack, Typography, Box } from '@mui/material';

import { BigidMeImage, BigidMeTextField, BigidMeTextFieldPropsType, MeButton } from '../../components';
import { ReactComponent as BigidMeIconInfo } from '../../assets/icons/info.svg';
import { LanguageContainer } from '../../state/languageContainer';
import { useToggle } from '../../hooks/useToggle';
import { BigidMeModal } from '../../components/BigidMeModal';
import appealInfoPNG from '../../assets/images/appeal-request-help.png';
import { useStyles } from './OriginalRequestIdFieldStyles';
import { e2e } from '../../utils';

export const OriginalRequestIdField: FC<BigidMeTextFieldPropsType> = ({ id, onChange, ...props }) => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const [openInfo, toggleOpenInfo] = useToggle();
  const params = new URLSearchParams(document.location.href);
  const defaultValue = params.get('originalRequestId');
  const classes = useStyles();

  useEffect(() => {
    if (!!defaultValue && onChange) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange]);

  return (
    <Stack direction="row" alignItems={['end', 'center']}>
      <BigidMeTextField
        id={id}
        onChange={onChange}
        {...props}
        defaultValue={defaultValue || undefined}
        disabled={!!defaultValue}
        helperEndAdornment={
          <IconButton
            {...e2e('original-request-id_info-modal-button')}
            disableRipple
            size="large"
            onClick={toggleOpenInfo}
            className={classes.iconButton}
          >
            <BigidMeIconInfo title={BigIdMeTranslate('consumer_web_info')} />
          </IconButton>
        }
      />
      {openInfo && (
        <BigidMeModal open onClose={toggleOpenInfo} wrapperClassName={classes.wrapper}>
          <Box px={5} mt={-3}>
            <Typography variant="h5" fontWeight="bold">
              {BigIdMeTranslate('consumer_web_id_number_explanation')}
            </Typography>
          </Box>
          <Box py={2}>
            <Divider />
          </Box>
          <BigidMeImage src={appealInfoPNG} alt={BigIdMeTranslate('consumer_web_id_number_explanation')} width="100%" />
          <Stack direction="row" justifyContent="flex-end" mt={2} px={5}>
            <MeButton
              bi={{ eventType: 'original_request_id_field_toggle_open_info' }}
              type="secondary"
              onClick={toggleOpenInfo}
            >
              {BigIdMeTranslate('consumer_web_close')}
            </MeButton>
          </Stack>
        </BigidMeModal>
      )}
    </Stack>
  );
};
