import React, { FC, useEffect, useState } from 'react';
import { TenantContainer } from '../../state/tenantContainer';
import { BigidMeTextField, BigidMeWireframe, MeButton } from '../../components';
import { StyledContainer, StyledQuestionsWrapper, StyledFormControl } from './LexisNexisVerificationScreenStyles';
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  FormLabel,
  CircularProgress,
  FormHelperText,
  Box,
} from '@mui/material';
import { verificationQuestionnaireService } from '../../services/verification-questionnaire/verificationQuestionnaireService';
import {
  LexisNexisQuestion,
  LexisNexisQuestionsResponse,
  LexisNexisResponseStatus,
} from '../../services/verification-questionnaire/types';
import { ReactComponent as BigidMeIconRadioEmpty } from '../../assets/icons/Radio_off_black.svg';
import { ReactComponent as BigidMeIconRadioSelected } from '../../assets/icons/Radio_on_black.svg';
import { LanguageContainer } from '../../state/languageContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { e2e } from '../../utils';
import { usePreventNavigation } from '../../hooks/usePreventNavigation';
import { usePreventLeavePage } from '../../hooks/usePreventLeavePage';
import { omit } from 'lodash';
import { LexisNexisAnswersStateType } from './types';
import { transformAnswers } from './utils';
import { ExitDialog } from '../../components/ExitDialog/ExitDialog';
import { RedirectUrls } from '../../constants/routes';
import { requestService } from '../../services';

export const LexisNexisVerificationScreen: FC = () => {
  const { BigIdMeTranslate } = LanguageContainer.useContainer();
  const { tenant, loadTenantSettings } = TenantContainer.useContainer();
  const navigate = useNavigate();
  const { requestId, rc } = useParams();
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState('');
  const [questions, setQuestions] = useState<LexisNexisQuestion[] | []>([]);
  const [answers, setAnswers] = useState<LexisNexisAnswersStateType>({});
  const [requestRunning, setRequestRunning] = useState(false);
  const statusScreenPath = `/${tenant.tenantId}/${requestId}/status/${rc}?skip=true`;

  useEffect(() => {
    loadTenantSettings({ requestId });
  }, [requestId, loadTenantSettings]);

  const goToVerificationStatusScreen = async (status: 'failed' | 'success' | 'manual') => {
    const requestType = (await requestService.getRequestStatus(requestId!, rc!)).requestType;
    navigate(`/${tenant.tenantId}/${requestId}/verification-status/${status}?type=${requestType}`);
  };

  const goToErrorScreen = () => {
    navigate(`/${tenant.tenantId}${RedirectUrls.ERROR}`);
  };

  const { showWarning, reset, retry } = usePreventNavigation(true, [
    statusScreenPath,
    `/${tenant.tenantId}${RedirectUrls.ERROR}`,
    `/${tenant.tenantId}/${requestId}/verification-status/failed`,
    `/${tenant.tenantId}/${requestId}/verification-status/success`,
    `/${tenant.tenantId}/${requestId}/verification-status/manual`,
  ]);
  usePreventLeavePage(true);

  const setQuestionsFromResponse = (data: LexisNexisQuestionsResponse) => {
    setSessionId(data.conversationId);
    setQuestions(data.questionSet.questions);
  };

  const getQuestionsAndHandleResponse = async (request: Promise<LexisNexisQuestionsResponse>) => {
    try {
      setRequestRunning(true);
      const data = await request;
      if (data.transactionStatus === LexisNexisResponseStatus.PENDING && data.questionSet.questions.length) {
        setQuestionsFromResponse(data);
      } else if (data.transactionStatus === LexisNexisResponseStatus.PASSED) {
        goToVerificationStatusScreen('success');
      } else if (data.transactionStatus === LexisNexisResponseStatus.FAILED) {
        goToVerificationStatusScreen('failed');
      } else {
        goToErrorScreen();
      }
    } catch (e) {
      goToErrorScreen();
    } finally {
      setRequestRunning(false);
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    requestId &&
      getQuestionsAndHandleResponse(
        verificationQuestionnaireService.submitLexisNexisAnswers(requestId, {
          conversationId: sessionId,
          answers: transformAnswers(answers),
        }),
      );
  };

  const handleChange = (questionId: number, value: string | number): void => {
    if (!value) {
      setAnswers(prevState => omit(prevState, questionId));
    } else {
      setAnswers(prevState => ({
        ...prevState,
        [questionId]: value,
      }));
    }
  };

  useEffect(() => {
    if (tenant.tenantId && requestId) {
      getQuestionsAndHandleResponse(
        verificationQuestionnaireService.sendRequestForLexisNexisInitialQuestions(requestId, rc || ''),
      );
    }
  }, [requestId, tenant.tenantId]);

  const isValid = Object.values(answers).length === Object.values(questions).length;

  return (
    <BigidMeWireframe {...tenant} pageTitle={BigIdMeTranslate('page_title_verification_form')}>
      <StyledContainer>
        <Box mb={22} textAlign="center">
          <Typography variant="h3" {...e2e('lexis_nexis_verification_form_title')}>
            {BigIdMeTranslate('consumer_web_submit_title', [{ key: 'tenant_caption', value: tenant.tenantName }])}
          </Typography>
          <Typography variant="h5" color="textPrimary">
            {BigIdMeTranslate('consumer_web_submit_subtitle')}
          </Typography>
        </Box>
        <Box mb={4} sx={{ width: '100%' }} px={12.5}>
          <Typography variant="h4" color="textPrimary">
            <b>{BigIdMeTranslate('consumer_web_submit_tell-us')}</b>
          </Typography>
        </Box>
        {loading || !questions.length ? (
          <CircularProgress color="inherit" />
        ) : (
          <StyledQuestionsWrapper>
            <Box mb={5}>
              <Typography variant="h5" color="textPrimary" fontWeight="bold">
                {BigIdMeTranslate('consumer_web_submit_description')}
              </Typography>
              <Divider />
            </Box>
            <div>
              <form>
                {questions.map(({ type, choices, text, questionId, helpText }) => {
                  if (type === 'singlechoice') {
                    return (
                      <StyledFormControl key={questionId}>
                        <FormLabel component="legend">{text}</FormLabel>
                        <RadioGroup key={questionId} name="questions">
                          {choices.map(({ text, choiceId }) => {
                            return (
                              <FormControlLabel
                                key={choiceId}
                                value={choiceId}
                                htmlFor={`${questionId}-${choiceId}`}
                                control={
                                  <Radio
                                    disableRipple
                                    onChange={() => handleChange(questionId, choiceId)}
                                    value={questionId}
                                    icon={<BigidMeIconRadioEmpty />}
                                    checkedIcon={<BigidMeIconRadioSelected />}
                                    checked={answers[questionId] === choiceId}
                                    color="primary"
                                    id={`${questionId}-${choiceId}`}
                                    {...e2e(`radio_${questionId}-${choiceId}`)}
                                  />
                                }
                                label={text}
                              />
                            );
                          })}
                        </RadioGroup>
                        {!!helpText && <FormHelperText>{helpText}</FormHelperText>}
                      </StyledFormControl>
                    );
                  }
                  if (type === 'fillInTheBlank') {
                    return (
                      <StyledFormControl key={questionId}>
                        <FormLabel component="legend">{text}</FormLabel>
                        <BigidMeTextField
                          placeholder={helpText}
                          onChange={value => handleChange(questionId, value)}
                          {...e2e(`text_${questionId}`)}
                        />
                        {!!helpText && <FormHelperText sx={{ marginTop: 3 }}>{helpText}</FormHelperText>}
                      </StyledFormControl>
                    );
                  }

                  return null;
                })}
                <Box mt={8}>
                  <MeButton
                    onClick={handleSubmit}
                    text="Submit"
                    type="primary"
                    classes={'styled'}
                    isDisabled={requestRunning || !isValid}
                    {...e2e('submit-button')}
                    bi={{ eventType: 'lexis_nexis_verification_submit' }}
                  />
                </Box>
              </form>
            </div>
          </StyledQuestionsWrapper>
        )}
      </StyledContainer>
      <ExitDialog
        open={showWarning}
        onCancel={() => {
          reset && reset();
        }}
        onClose={() => {
          retry && retry();
        }}
      />
    </BigidMeWireframe>
  );
};
