import { getOriginalAppUiUrl, resolveUrlForBigIDCoreAppWithProxy } from './bigIDCoreAppWithProxyUtils';

export const resolveUrlForApp = (url?: string) => {
  if (isBigIdCoreAppWithProxy()) {
    return resolveUrlForBigIDCoreAppWithProxy(url);
  }
  return url;
};

export const isBigIdCoreAppWithProxy = () => isBigIDCoreApp() && !!getOriginalAppUiUrl();

export const isBigIDCoreApp = () => window.self !== window.top;
