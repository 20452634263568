interface AppFwSdk {
  getToken(): Promise<string>;
  getBffUrl(): Promise<string>;
}

export class AppFwService {
  private sdkInstance?: AppFwSdk;

  setSdk(sdkInstance: AppFwSdk) {
    this.sdkInstance = sdkInstance;
  }

  private getSdk() {
    if (!this.sdkInstance) {
      throw new Error('SDK not set');
    }
    return this.sdkInstance;
  }

  getToken() {
    return this.getSdk().getToken();
  }

  getBffUrl() {
    return this.getSdk().getBffUrl();
  }
}

export const appFwService = new AppFwService();
